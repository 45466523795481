import * as React from 'react'
import type { HeadFC } from 'gatsby'

import rosetta from 'rosetta'

const i18n = rosetta({
  en: {
    welcome: 'Welcome!',
  },
  ko: {
    welcome: '환영합니다!',
  },
})

i18n.locale('ko')

const IndexPage = () => {
  const [, forceUpdate] = React.useState(0)
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const browserLocale = navigator.language.startsWith('ko') ? 'ko' : 'en'

      if (browserLocale !== 'ko') {
        i18n.locale(browserLocale)
        forceUpdate((value) => value + 1)
      }
    }
  }, [])
  return (
    <main>
      <h1>{i18n.t('welcome')}</h1>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
  <title>Support i18n to Gatsby in hydration time</title>
)
